
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIBasicUsage from "@/views/resources/documentation/element-ui/form/checkbox/BasicUsage.vue";
import EUIDisabledState from "@/views/resources/documentation/element-ui/form/checkbox/DisabledState.vue";
import EUICheckboxGroup from "@/views/resources/documentation/element-ui/form/checkbox/CheckboxGroup.vue";
import EUIIndeterminate from "@/views/resources/documentation/element-ui/form/checkbox/Indeterminate.vue";
import EUIMinMaxItemsChecked from "@/views/resources/documentation/element-ui/form/checkbox/MinMaxItemsChecked.vue";
import EUIButtonStyle from "@/views/resources/documentation/element-ui/form/checkbox/ButtonStyle.vue";
import EUIWithBorders from "@/views/resources/documentation/element-ui/form/checkbox/WithBorders.vue";

export default defineComponent({
  name: "checkbox",
  components: {
    EUIBasicUsage,
    EUIDisabledState,
    EUICheckboxGroup,
    EUIIndeterminate,
    EUIMinMaxItemsChecked,
    EUIButtonStyle,
    EUIWithBorders
  },
  setup() {
    setCurrentPageTitle("Checkbox");
  }
});
